<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'OptimizationMainPixels',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Line item IDs',
          value: (alert) => {
            const filtered = alert.data.li_data.filter((li) => {
              return (li.environment_targeting.includes('Web')) && (li.environment_targeting.includes('App'))
            })
            const ids = alert.data.all_or_some_li === 'all' ? alert.data.li_data.map(li => li.id) : filtered.map(li => li.id)
            return `<br>- id: ${this.$commonUtils.htmlEntitiesList(ids).join('<br>- id: ')}`
          }
        }
      ],
      settingsData: [
        {
          label: 'KPI',
          value: 'settings.KPI'
        }
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
